import Link from "next/link";
import styles from "./BorderedLink.module.css";
import classNames from "classnames";

const BorderedLink = ({ href, active, children }) => {
  const classes = classNames(styles.link, {
    [styles.active]: active,
  });

  return (
    <Link legacyBehavior href={href} passHref>
      <a className={classes}>{children}</a>
    </Link>
  );
};

export default BorderedLink;
